<template>
  <v-form @submit.prevent="saveForm" ref="form">
    <BreadcrumbsField :items="itemsBreadcrumbs"/>
    <p class="display-1 px-3">{{ name }}</p>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-text-field
            :label="currentItem.name_verbose_name"
            v-model="name"
            disabled
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import BreadcrumbsField from '@/apps/core/components/forms/BreadcrumbsField'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ProductCategoryForm',
  components: { BreadcrumbsField },
  data () {
    return {
      storeModule: 'productsCategory',
      itemsBreadcrumbs: [
        {
          text: this.$t('ProductCategory.list'),
          disabled: false,
          name: 'ProductCategoryList'
        },
        {
          text: this.$t('ProductCategory.title'),
          disabled: true
        }
      ],
      productCategoryId: this.$route.params.id
    }
  },
  created () {
    if (this.productCategoryId > 0) {
      this.getItem(this.productCategoryId)
    }
  },
  computed: {
    ...mapState('productsCategory', ['currentItem']),
    name: {
      get () {
        return this.currentItem.name
      }
    }
  },
  methods: {
    ...mapActions('productsCategory', ['getItem'])
  }
}
</script>

<style scoped>

</style>
