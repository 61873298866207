<template>
  <Layout :top-bar-title="title" :sections="sections" :baseLayoutInfoUrl="'/base-layout-info/'">
    <ProductCategoryForm/>
  </Layout>
</template>

<script>
import Layout from '@/apps/core/components/Layout'
import sections from '@/apps/dido/views/defaultNavigationDrawerSections'
import ProductCategoryForm from '@/apps/titan/components/forms/ProductCategoryForm'

export default {
  name: 'ProductCategoryDetail',
  components: { Layout, ProductCategoryForm },
  data () {
    return {
      sections: sections,
      title: 'Categoría de producto'
    }
  }
}
</script>

<style scoped>

</style>
